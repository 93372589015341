import React, { useState, useRef, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from "react-intl";
import _toLower from 'lodash/toLower'
import Popover from '@/shared/ui/Popover';
import s from './SelectSubaccount.module.scss';
import Input from "@/shared/ui/Input";
import { trimAddress } from "@/shared/utils/formatters";
import CoinIcon from "@/shared/ui/CoinIcon";
import { IconSearch, IconSupport, IconSortUnfold, IconArrowTo } from "@/shared/ui/Icons";
import { 
  setMinerAccount,
  addToRecentSearches,
  useMinerAccount,
} from "@/processes/MinerAccounts";
import { useNavigation } from '@/processes/Router';
import { useGetAccountsQuery } from "~/Header/api";

type SelectSubaccountProps = {
  className?: string;
}

const SelectSubaccount = (props: SelectSubaccountProps) => {
  const {
    className = '',
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { coin, isSupportSubAccount, isGRAM, getPoolDashboardLink, navigate } = useNavigation();
  const {
    account,
    miner,
    isSupport, 
    isAccounting,
    recentSearches, 
    isAuth,
    findValidAddress,
  } = useMinerAccount();

  const { data: accounts = [] } = useGetAccountsQuery(null, { skip: !(isAuth && isSupportSubAccount) });

  let selectedAccount;
  if (isAccounting) {
    if (isGRAM) {
      selectedAccount = !!miner.length ? trimAddress(miner) : account;
    } else {
      selectedAccount = account;
    }
  } else {
    selectedAccount = trimAddress(miner);
  }

  const searchRef = useRef(null);  
  const [searchQuery, setSearchQuery] = useState('');
  
  const isSupportAccount = isSupport && account && !accounts.find((a: any) => a.name === account);

  const minerAccountOptions = useMemo(() => {
    const options = accounts.filter((a: any) => !a.deactivated).map((a: any) => {
      return {
        value: a.name,
        label: a.name,
      };
    });
    // if support opened account which is not existing, add this accoun to the end of the list
    if (isSupportAccount) {
      options.push({
        value: account,
        label: `${account} - [support]`,
      });
    }
    return options;
  }, [accounts, isSupportAccount, account]);

  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const filterAccounts = ((item: any) => {
    const { value } = item;
    return _toLower(value).includes(_toLower(searchQuery));
  });
  const minerAccountOptionsFiltered = minerAccountOptions.filter(filterAccounts);

  const filterMinerAddresses = ((item: any) => {
    const { address } = item;
    return _toLower(address).includes(_toLower(searchQuery));
  });
  const recentSearchesFiltered = recentSearches.filter(filterMinerAddresses);

  const handleSubAccountSelect = (value: string) => {
    dispatch(setMinerAccount({ selectedAccount: value }));
    setSearchQuery('');
    setIsOpen(false);
    const dashboardUrl = getPoolDashboardLink({ account: value, coin, support: isSupport });

    navigate(dashboardUrl, undefined, { shallow: true });
  };
  const { isValid: isSearchValid, coin: searchCoin } = findValidAddress(searchQuery);

  const handleMinerAddressSelect = ({ coin, address, isSearch = false }: any) => {
    setSearchQuery('');
    setIsOpen(false);
    // save to recent searches
    if (isSearch) {
      dispatch(addToRecentSearches({ coin, address }));
    }
    // navigate to dashboard
    const dashboardUrl = getPoolDashboardLink({ miner: address, account: null, coin });
    navigate(dashboardUrl);
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    if (isSearchValid) {
      handleMinerAddressSelect({ address: searchQuery, coin: searchCoin, isSearch: true })
    }
  }


  // TODO: add loader
  // add <TextLoader width="56px" height="26px" className="rounded-lg" />

  return (
    <Popover
      open={isOpen}
      onOpenChange={setIsOpen}
      align='start'
      triggerComponent={
        <button
          className={cx( className, s.button)}
        >
          {isSupportAccount ? (
            <IconSupport className="h-3 w-3 mr-1.5"/>
          ) : null}
          {selectedAccount
            ? `${selectedAccount}`
            : intl.formatMessage({ id: 'header.subaccount.placeholder' })
          }
          <IconSortUnfold className="ml-2 w-3"/>
        </button>
      }
      className='w-[260px] z-100 sm:w-[332px]'
    >
      <div className={s.list}>
        <form onSubmit={handleFormSubmit}>
          <Input
            ref={searchRef}
            prefix={<IconSearch className="text-white" />}
            placeholder={intl.formatMessage({id: 'header.subaccount.select.input.placeholder'})}
            value={searchQuery}
            onChange={handleSearchChange}
            full
          />
        </form>
        <div className={s.listContainer}>
          {/* miner addresses */}
          {recentSearchesFiltered.length > 0 ? (
            <>
              <div className={cx(s.listTitle)}><FormattedMessage id="header.subaccount.select.recenlty.title" /></div>
              {recentSearchesFiltered.map(({coin, address}: any) => (
                <button
                  type="button"
                  key={address}
                  className={cx(s.item, 'justify-between')}
                  onClick={() => handleMinerAddressSelect({ coin, address })}
                >
                  <span className='flex items-center'>
                    <CoinIcon coin={coin} className='h-3 w-3 mr-1'/>
                    {trimAddress(address)}
                  </span>
                  <IconArrowTo className={cx(s.arrowTo, "ml-1 w-4")}/>
                </button>
              ))}
            </>
          ) : (
            isSearchValid ? (
              <>
                <div className={s.listTitle}><FormattedMessage id="header.subaccount.select.search.result" /></div>
                <button
                  type="button"
                  key={searchQuery}
                  className={cx(s.item, 'justify-between')}
                  onClick={() => handleMinerAddressSelect({ coin: searchCoin, address: searchQuery, isSearch: true })}
                >
                  <span className='flex items-center'>
                    <CoinIcon coin={searchCoin} className='h-3 w-3 mr-1'/>
                    {trimAddress(searchQuery)}
                  </span>
                  <IconArrowTo className={cx(s.arrowTo, "ml-1 w-4")}/>
                </button>
              </>
            ) : null
          )}              

          {/* sub accounts */}
          {isAccounting && minerAccountOptionsFiltered.length > 0 ? (<>
            <div className={s.listTitle}><FormattedMessage id="header.subaccount.select.title" /></div>
            
            {minerAccountOptionsFiltered.map((option: any) => {
              const { value, label } = option;
              return (
              <button
                type="button"
                key={value}
                className={cx(s.item)}
                onClick={() => handleSubAccountSelect(value)}
              >
                {label}
              </button>
              );
            })}
          </>) : null}
        </div>
      </div>
    </Popover>
  );
}

export default SelectSubaccount;
